/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { requestBaseURL } from '@/config/serviceAPI';
import { SearchSkuRecordResponse } from '../interface';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { useLocale } from '@/hooks/useLocale';
import { useSegments } from '@/features/header/api/getSegments';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { NextRouter, useRouter } from 'next/router';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { Locale } from '@/types/i18n';
import { AxiosInstance } from 'axios';
import { parseUrl, sanitizeUrlWithParamList } from '@/utils/urlHelpers';
import { acceptableShelfQueryParams } from '@/constants/queryParams';
import { routePaths } from '@/constants/routePaths';

const SEARCH_SKU_RECORD_URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/search-product`;
const CUSTOMER_TYPE = 'B2C';
const SALES_CHANNEL = 'ECOMM';
const STALE_TIME_5_MINUTES = 60000 * 5;

type SearchSkuRecordDataOptions = {
  locale: Locale;
  searchText: string;
  userSegments?: string[];
  vehicleId?: string;
};

const onSuccess = (data: SearchSkuRecordResponse, router: NextRouter) => {
  if (data.redirectUrl) {
    const { url, query } = parseUrl(data.redirectUrl);
    const sanitizedRedirectPath = sanitizeUrlWithParamList(
      {
        ...query,
        ...(router.query.searchText && {
          searchText: router.query.searchText,
        }),
        redirectUrl: 'true',
      },
      acceptableShelfQueryParams,
      url
    );

    void router.replace(sanitizedRedirectPath);
  }
};

const getSearchSkuRecordData = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getSearchSkuRecordDataKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, { locale, searchText, userSegments, vehicleId }] = queryKey;
  const country = getCountryFromLocale(locale);
  const preview = showXMPreviewDate();
  const { data } = await getAxios(axiosInstance).get<SearchSkuRecordResponse>(
    SEARCH_SKU_RECORD_URL,
    {
      params: {
        country,
        customerType: CUSTOMER_TYPE,
        salesChannel: SALES_CHANNEL,
        searchText,
        userSegments: userSegments?.join(','),
        vehicleId,
        preview,
      },
    }
  );
  return data;
};

const getSearchSkuRecordDataKey = ({
  locale,
  searchText,
  userSegments,
  vehicleId,
}: SearchSkuRecordDataOptions) =>
  ['searchRecordData', { searchText, locale, userSegments, vehicleId }] as const;

export const useSearchSkuRecordData = () => {
  const isContentStackSRPEnabled = useMonetateDecisionFlag('cmsSearchResultsPageEnabled');
  const locale = useLocale();
  const { data: segments, isSuccess: segmentsSuccess } = useSegments();
  const router = useRouter();
  const preferredVehicle = usePreferredVehicle();

  const searchText = (router.query.searchText as string) ?? '';

  return useQuery({
    queryKey: getSearchSkuRecordDataKey({
      locale,
      searchText,
      userSegments: segments?.sort() ?? [],
      vehicleId: preferredVehicle?.catalogVehicleId ?? '0',
    }),
    queryFn: getSearchSkuRecordData,
    enabled:
      !!isContentStackSRPEnabled &&
      router.pathname === routePaths.searchResult &&
      !!searchText &&
      segmentsSuccess,
    staleTime: STALE_TIME_5_MINUTES,
    onSuccess: (data) => onSuccess(data, router),
  });
};

export const prefetchSearchSkuRecordData = ({
  queryClient,
  axiosInstance,
  ...options
}: SearchSkuRecordDataOptions & { queryClient: QueryClient; axiosInstance: AxiosInstance }) => {
  return queryClient.prefetchQuery({
    queryKey: getSearchSkuRecordDataKey(options),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getSearchSkuRecordDataKey>>) =>
      getSearchSkuRecordData(context, axiosInstance),
  });
};

export const getSearchSkuRecordDataFromCache = ({
  queryClient,
  ...options
}: SearchSkuRecordDataOptions & { queryClient: QueryClient }) => {
  return queryClient.getQueryData<SearchSkuRecordResponse>(getSearchSkuRecordDataKey(options));
};
