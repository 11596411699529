/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { FacetModel } from '@/api/types/browse-search-types';
import { useLabel } from '@/hooks/useLabels';
import { FilterDataType } from '@/types/filterTypes';

export const getMapFacet = (facet: FacetModel, clearanceText: string): FilterDataType => ({
  displayName: facet.facetDisplayName,
  name: facet.facetDisplayName,
  dimensionName: facet.facetInternalName,
  refinements: (
    (facet.facetInternalName === 'clearance_i'
      ? facet?.facetValues
          ?.filter((refinement) => refinement.facetValueDisplayName !== '0')
          .map((item) => {
            if (item.facetValueDisplayName === '1') {
              item.facetValueDisplayName = clearanceText;
            }
            return item;
          })
      : facet.facetValues) ?? []
  )?.map((refinement) => ({
    count: refinement.itemCount ?? 0,
    label: refinement.facetValueDisplayName,
    properties: {
      seoUrl: refinement.seoUrl,
      isSelected: refinement.selectedFlag ?? false,
      optimizedSeoUrl: refinement.seoUrl,
      internalName: refinement.facetValueInternalName,
      filters: '',
      unCheckedOptimizedUrl: refinement.seoUrl ?? '',
      botsOptimizedSeoUrl: refinement.botSeoUrl ?? '',
    },
    multiSelect: facet.multiSelectFlag ?? false,
    '@class': '',
    navigationState: '',
  })),
  '@type': 'SearchLeftNav_RefinementMenu',
  multiSelect: facet.multiSelectFlag ?? false,
  filter: undefined,
});

export const useMapFacet = () => {
  const clearanceText = useLabel('label_clearance_badge');

  return (facet: FacetModel) => getMapFacet(facet, clearanceText);
};
