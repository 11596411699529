/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { AxiosInstance } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import type {
  BrandShelfResponseModel,
  FacetModel,
  GetBrandListResultsUsingGetParamsModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useRouter } from 'next/router';
import { QueryClient } from '@tanstack/react-query';
import { parseUrl } from '@/utils/urlHelpers';
import { getMapFacet, useMapFacet } from '@/hooks/useMapFacet';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import type { GetProductBrandDataOptions, MappedBrandShelfResults } from '../interface';

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/brand-shelves`;

const selector = (
  { brandShelfResults, redirectUrl, xFusionQueryId }: BrandShelfResponseModel,
  mapFacet: ReturnType<typeof useMapFacet>
): MappedBrandShelfResults => {
  return {
    redirectLocation: redirectUrl,
    recordsPerPage: brandShelfResults?.recordsPerPage ?? 12,
    total: brandShelfResults?.totalNumberOfRecords,
    quickFilters: brandShelfResults?.quickFilters,
    firstRecNum: brandShelfResults?.firstRecordNumber,
    lastRecNum: brandShelfResults?.lastRecordNumber,
    staticNavigation: brandShelfResults?.facets?.map(mapFacet) ?? [],
    brandName: brandShelfResults?.brandName ?? '',
    records: brandShelfResults?.skuRecords?.map((record) => ({
      ...record,
      active: record.activeFlag,
      alternatePartNumber: record.alternatePartNumber,
      brandName: record.brandName,
      seoUrl: record.productDetailsPageUrl,
      imageUrl: record.itemImageUrl ?? '',
      productImageUrl: record.itemImageUrl ?? '',
      description: record.itemDescription,
      skuNumber: Number(record.itemId),
      locationFilter: record.itemLocation,
      lineCode: record.lineCode,
      systemCode: Number(record.originalSystemCode),
      partNumber: record.partNumber,
      productRepositoryId: record.eCommerceProductId,
      productId: record.eCommerceProductId,
      quickNote: record.quickNote,
      techNote: record.technicalNote,
      recordType: record.recordType,
      skuReviewEnabled: record.reviewEnabledFlag,
      vehicleFitmentLabel: record.vehicleFitmentLabel,
      warrantyMonths: record.warrantyMonths,
      brand: record.brandName ?? '',
      name: record.itemDescription,
      productReviewsEnabled: record.reviewEnabledFlag,
      vehicleFit: record.vehicleFitmentFlag ?? false,
      vehicleSpecific: record.vehicleSpecificFlag ?? false,
      originalPartTypeId: record.originalPartTypeId ?? '',
    })),
    xFusionQueryId,
  };
};

const getBrandProductData = async (
  options: GetProductBrandDataOptions,
  axiosInstance?: AxiosInstance
) => {
  const { locale, mapFacet, ...rest } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  const params: GetBrandListResultsUsingGetParamsModel = {
    ...rest,
    country,
    customerType,
    salesChannel,
  };
  const response = await getAxios(axiosInstance).get<BrandShelfResponseModel>(URL, {
    params,
  });

  return selector(
    response.data,
    mapFacet ?? ((facet: FacetModel) => getMapFacet(facet, 'Clearance'))
  );
};

const {
  useData: useBrandProducts,
  prefetch: prefetchProductBrand,
  query: productBrandQuery,
} = createQuery<MappedBrandShelfResults, GetProductBrandDataOptions>(
  'brandProducts',
  getBrandProductData
);

export const useBrandProductData = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const locale = useLocale();
  const router = useRouter();
  const mapFacet = useMapFacet();
  const preferredVehicle = usePreferredVehicle();
  const { data: storeDetailsData } = useStoreDetailsData();
  const is24ProductViewEnabled = useIs24ProductViewEnabled();
  const storeNumber = storeDetailsData?.storeNumber;
  const vehicleId = preferredVehicle?.catalogVehicleId;
  const {
    currentPage,
    sort = [],
    recsPerPage,
    pageNumber,
    facet,
    minPrice,
    maxPrice,
  } = router.query;
  const sortArr = typeof sort === 'string' ? sort.split('-') : sort;
  const sortOrder = sortArr[sortArr.length - 1];
  const sortFieldName = sortArr.slice(0, sortArr.length - 1).join('-');

  const path = router.asPath;
  const parsedUrl = parseUrl(path);
  const options = {
    locale,
    pageNumber:
      typeof currentPage === 'string'
        ? Number(currentPage)
        : typeof pageNumber === 'string'
        ? Number(pageNumber)
        : 1,
    vehicleId,
    recordsPerPage:
      typeof recsPerPage === 'string'
        ? Number(recsPerPage)
        : is24ProductViewEnabled
        ? 24
        : undefined,
    minPrice: minPrice ? String(minPrice) : undefined,
    maxPrice: maxPrice ? String(maxPrice) : undefined,
    facet,
    sort: sortFieldName && sortOrder ? `${sortFieldName}-${sortOrder}` : undefined,
    storeId: storeNumber,
    seoUrl: parsedUrl.url,
    preview: showXMPreviewDate(),
    enabled: enabled,
    mapFacet,
    onSuccess: async (data: MappedBrandShelfResults) => {
      if (data.redirectLocation) {
        await router.replace(data.redirectLocation);
      }
    },
  };
  return useBrandProducts(options);
};

export const getBrandProductDataFromCache = (
  queryClient: QueryClient,
  options: GetProductBrandDataOptions
) => {
  return queryClient.getQueryData<MappedBrandShelfResults>(productBrandQuery.getFullKey(options));
};

export { prefetchProductBrand };
