/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const productListingPageTypes = {
  default: 'Default',
  merchShelf: 'Merch Shelf',
  brandShelfDefault: 'Brand Shelf (Default)',
  brandShelfPremium: 'Brand Shelf (Premium)',
  searchResult: 'Search Result',
} as const;

export type ProductListingPageType =
  (typeof productListingPageTypes)[keyof typeof productListingPageTypes];
