/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { usePageType } from '@/features/category/api/getPageType';
import type { ContentStackProductListingPage } from '@/features/contentstack/interface';
import { getContentStackPLPContent } from '@/features/contentstack/lib/productListingPage/getContentStackPLPContent'; // eslint-disable-line no-restricted-imports
import { useSegments } from '@/features/header/api/getSegments';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { getAxiosForClient } from '@/lib/axios';
import { PageContextType } from '@/next-types';
import { QueryClient, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { generateContentStackPLPParams } from '../utils/generateContentStackPLPParams';
import { type ProductListingPageType, productListingPageTypes } from '../constants';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useSearchSkuRecordData } from '@/features/search/api/getSearchRecordData';

const URL = `/api/contentstack/plp`;

const getContentStackProductListingPageData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCSProductListingPageKey>>) => {
  const [, , { taxonomyPath, segments, productListingPageType, url }] = queryKey;
  const { data } = await getAxiosForClient().get<ContentStackProductListingPage>(URL, {
    params: {
      segments: segments?.sort().join(','),
      taxonomyPath,
      productListingPageType,
      url,
    },
  });
  return data;
};

const getCSProductListingPageKey = ({
  productListingPageType,
  segments,
  taxonomyPath,
  url,
}: {
  segments: string[];
  productListingPageType?: ProductListingPageType;
  taxonomyPath?: string;
  url?: string;
}) => ['cms', 'plp', { productListingPageType, segments, taxonomyPath, url }] as const;

export const useContentStackPLPData = ({ enabled = true }: { enabled?: boolean }) => {
  const router = useRouter();
  const { data: segments, isSuccess } = useSegments();
  const isCSShelfPageEnabled = useMonetateDecisionFlag('cmsShelfPageEnabled') ?? false;
  const isCSBrandShelfPageEnabled = useMonetateDecisionFlag('cmsBrandShelfPageEnabled') ?? false;
  const isCSSearchResultsPageEnabled = useMonetateDecisionFlag('cmsSearchResultsPageEnabled');
  const { data: pageTypeData } = usePageType();
  const { data: searchSkuRecordData } = useSearchSkuRecordData();

  const { productListingPageType, taxonomyPath, url, isCSProductListingPageEnabled } =
    generateContentStackPLPParams({
      router,
      isCSShelfPageEnabled,
      isCSBrandShelfPageEnabled,
      isCSSearchResultsPageEnabled,
      pageTypeData,
      searchSkuRecordData,
    });

  const queryResponse = useQuery({
    queryKey: getCSProductListingPageKey({
      productListingPageType,
      segments: segments?.sort() ?? [],
      taxonomyPath,
      url,
    }),
    staleTime: 30 * 60000,
    queryFn: getContentStackProductListingPageData,
    onError: async (error) => {
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting Product Listing Page content: `, error);
        await router.replace('/errorPage');
        return;
      } catch (error) {
        throw new Error(`There was a problem redirecting to /errorPage`);
      }
    },
    enabled: enabled && isSuccess && isCSProductListingPageEnabled && (!!taxonomyPath || !!url),
  });

  const isBrandPage = router.pathname === '/brands/[brandName]';

  const { data: brandData } = useBrandProductData({ enabled: isBrandPage });
  const premiumBrands =
    queryResponse.data?.additional_attributes_default_brand_shelf?.premium_brands?.map((brand) =>
      brand.toLowerCase()
    ) ?? [];
  const isBrandPremiumPage =
    productListingPageType === productListingPageTypes.brandShelfDefault &&
    premiumBrands?.includes(brandData?.brandName?.toLowerCase() ?? '');

  const premiumBrandQueryResponse = useQuery({
    queryKey: getCSProductListingPageKey({
      productListingPageType: productListingPageTypes.brandShelfPremium,
      segments: segments?.sort() ?? [],
      taxonomyPath,
      url,
    }),
    staleTime: 30 * 60000,
    queryFn: getContentStackProductListingPageData,
    enabled: isSuccess && !!isBrandPremiumPage,
  });

  return {
    isCSProductListingPageEnabled,
    ...(isBrandPremiumPage ? premiumBrandQueryResponse : queryResponse),
  };
};

export const prefetchContentStackProductListingPageData = ({
  queryClient,
  taxonomyPath,
  context,
  userSegments,
  productListingPageType,
  url,
}: {
  queryClient: QueryClient;
  taxonomyPath?: string;
  context: PageContextType;
  userSegments: string[];
  productListingPageType: ProductListingPageType;
  url?: string;
}) => {
  return queryClient.prefetchQuery({
    queryKey: getCSProductListingPageKey({
      segments: userSegments,
      taxonomyPath,
      productListingPageType,
      url,
    }),
    queryFn: () =>
      getContentStackPLPContent(
        context.req,
        { ...context.query, taxonomyPath, productListingPageType, url },
        userSegments
      ),
  });
};

export const getPLPDataFromCache = ({
  queryClient,
  taxonomyPath,
  userSegments,
  productListingPageType,
  url,
}: {
  queryClient: QueryClient;
  taxonomyPath?: string;
  userSegments: string[];
  productListingPageType: ProductListingPageType;
  url?: string;
}) => {
  return queryClient.getQueryData<ContentStackProductListingPage>(
    getCSProductListingPageKey({
      segments: userSegments,
      taxonomyPath,
      productListingPageType,
      url,
    })
  );
};
